import React from 'react'
import Slider from 'react-slick'
import { graphql, StaticQuery } from 'gatsby'
import Fade from "react-reveal/Fade"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Layout from "../layouts/App"
import SEO from "../components/seo"

import Spotlight from '../components/Spotlight'

var settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [{
            breakpoint: 980,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export default ({pageContext}) => (
    <Layout>
        <SEO title="About" image="https://sys.wktokyo.com/wp-content/uploads/wk-about.gif" description={pageContext.node.acf.metaPageDescription}/>
        <article className="about_page">
            <Fade bottom distance={`100px`}>
            <Spotlight image={pageContext.node.acf.spotlightImage?.mediaItemUrl} />

            </Fade>
            <section className="container-fluid-custom-only content-body min--width--coloumn">
                <StaticQuery query={graphql`
                    {
                        allWpClient {
                            edges {
                              node {
                                name
                                acf {
                                clinetLogo {
                                    sourceUrl
                                  }
                                }
                                clientInfo {
                                    display_on_page
                                    website
                                }
                              }
                            }
                          }
                    }
                `} render={props => {
                    return(
                        <>
                        <Fade bottom distance={`100px`}>
                        <h1>About us</h1>
                        <p dangerouslySetInnerHTML={{__html:pageContext.node.content}} />
                        </Fade>
                        <Fade bottom distance={`100px`}>
                            <p>Our current clients included:</p>
                            <div className="client--parent--wrap">
                                <div className="client--inner--wrap">
                                <ul>
                                {props.allWpClient.edges.map((client, index) => {
                                    //If display on page is true.
                                     if(client.node.clientInfo.display_on_page == 'yes'){
                                        return(
                                                <li>
                                                    <img title={client.node.name} alt={client.node.name} src={client.node.acf.clinetLogo.sourceUrl} />
                                                </li>
                                        
                                            )
                                        }
                                    })
                                }
                                </ul>
                                </div>
                            </div>
                        </Fade>
                        </>
                    )
                }} />
            </section> 
                   </article>
    </Layout>
)